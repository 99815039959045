.landing-photo-interface {
  display: flex;
  width: 100%;
  
  justify-content: space-between;
  background-color: #dfb3dfb3;
  justify-content: center;
  align-items: center;

}

.img-landing-container {
 width: 300px;
 
}


.img-landing-container img{
  width: 100%;
}

.landing-photo-interface a {
  color: white;
}

.landing-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  
  
  color: rgb(255, 255, 255);

}


.box {
  padding: 2.5px;
}
.services h3{

  font-size: 20px;

  
}


.icon {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  color: #dfb3dfb3 ; 
}


.interface-img-container {
  width: 400px;

}

.interface-img-container img {
  width: 100%;
  
}



.landing-description h1 {
  font-size: 60px;
  
}

.landing-description p {
  
  font-size: 20px;
   margin-right: 130px; 
}

.landing-description i {
  margin-left: 5px;
  margin: 10px 0px;
  color: white;
}




.landing-description a {
   margin-right: 180px; 
  border: 1px solid white;
  border-radius: 20px;
  width: 200px;
  height: 40px;
  text-align: center;

}




.hover-landing:hover {
  background-color: white;
  color: black ;
  transition: 0.2s;

}

.hover-landing:hover  i{
  color: black;
  transition: 0.2s;
}





.social-media-container {
  display: flex;
  background-color: #f3f3fa;
  width: 100%;
 
  justify-content: center;
  align-items: center;

  height: 300px;
}

.social-media-container i {
  font-size: 40px;
  color: black;
}


.absolute-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  transform: translateY(-50%);
}

.absolute-buttons-container button {
  width: 20%;
  max-width: 250px;
  height: 50px;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.absolute-buttons-container button:hover {
  background-color: #dfb3dfb3;
  color: white;
  transition: 0.2s;
}


.about-location-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
  margin-top: 100px;
  flex-wrap: wrap;
}

.img-about-container {
  width: 300px;
  height: 350px;
  background-color: #dfb3dfb3;
  border-radius: 50%;

}





.img-about-container img {
  width: 80%;
  height: 90%;
  border-radius: 40%;
  margin-left: 50px;
  margin-top: 10px;
}

.about-description {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.about-description p {
  width: 90%;
  max-width: 400px;
  
}

.checked-abilities-card p{

  text-align: center;
}

.about-description span {
  color: #dfb3df;
  Font-size: 20px;
}

.vector-container {
      display: flex;
      gap: 10px;
}
.vector-container i{
  margin-top: 3px;
  color: #dfb3df;
}
.checked-abilities-card p{
  width: 250px;
  padding: 10px;
  margin-left: 10px;
}
.checked-abilities-card i {
  margin-right: 5px;
}

.checked-abilities-card {
  display: flex;
  flex-wrap: wrap;
  
  
}






.img-carousel-container {
  width: 80%;
  max-width: 400px;
  min-width: 250px;
  height: 400px;

}







.services {
  margin-top: 150px;
  text-align: center;
  background-color: #f3f3fa;
  padding: 30px;
  width: 100%;

}
.services h2 {
  margin-bottom: 60px;
}
.boxes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
}

.boxes-container .box {
  width: 300px;
  height: 280px;
  border-bottom: #dfb3df 3px solid;
  background-color: white;
 
}
.boxes-container {
  width: 100%;
  margin: auto;
  gap: 50px;
  margin-top: 20px;
}
.title span, i{
  margin-top: 10px;
}
.title h2{
 margin-top: 50px;
}



.vector-container-services {
  display: flex;
  justify-content: center;
  gap: 10px;
  color: #dfb3df;
  
}

.boxes-container i{
  font-size: 40px;
  
  
  margin-top: 20px;

}

.box {
  display: flex;
  flex-direction: column;
}

.box p {
  width: 200px;
  margin: auto;
  font-size: 18px;

}
.box h3 {
  margin-top: 10px;
  
  
}



.box i {
  color: #dfb3df;
}

.box:hover  {
  background-color: #dfb3df;
  color: white;
  transition: 0.2s;
 
}

.box:hover i {
  color: white;
  transition: 0.2s;
}

.icon {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  color: #dfb3dfb3 ; 
}


.box:hover  {
  color: white;
  transition: 0.2s;
}



.box i {
  
  margin: 18px auto;
}



a {
  text-decoration: none !important;
}

.blog {
  
  text-align: center;

}

.services-contact-button {

  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px auto;
  text-align: center;
}


.second-a-contact {
  width: 300px;
  padding: 5px;
  border-radius: 20px;
  height: 50px;
  background-color: white;
  color: black;
}



.cards-container {
  width: 100%;
 justify-content: center;
  align-items: center;
  display: flex;
  margin: 50px auto;
  gap: 50px;
  flex-wrap: wrap;

}

 .card { 
  
  width: 25%;
  min-width: 280px !important;
  max-width: 330px !important;

height: 500px;
}




.img-container {
  width: 100%;
  height: 350px;
  
  
}

.img-container  img,video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  
  
  
}





.description-card h4 {
  margin-top: 10px;
  font-size: 18px;
  color: #dfb3df;
}

.description-card {
  display: flex;
  justify-content: space-around;
}

.card a {
  padding: 8px;

  
}

.card i {
  
  margin-left: 10px;
}

.card i {
  
  background: none;
 
}

.social-media {
  margin-top: 200px;
}



.card a {
  background-color: #f3f3fa;
  border: none;
  font-size: 15px;
  border-radius: 20px;
  margin-top: 10px;
  width: 70%;
  margin: auto;
  height: 60px; 
  color: black;
}

.description-card h4 {
  color: #dfb3df;
}



.card a:hover{
  background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d) ;
  color: white;
  transition: 0.2s;
}


.second-a-contact:hover {
  background-color: #dfb3df;
  color: white;
  transition: 0.2s;
}



.hover-container:hover .fa-instagram {
  color: white;
  transition: 0.2s;
}


.card a {
  font-size: 20px;
  font-weight: bold;
}

.follow-container p{
    width: 250px;
}

.follow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
 justify-content: space-evenly;
  
  flex-wrap: wrap;
}


.vector-container-blog {
  margin-top: 50px
}

.vector-container-blog span {
  
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #dfb3df;
}

.vector-container-blog i {
  margin-top: -5px;
  color: #dfb3df;
}

.blog h2 {
  margin-top: 50px;
}


.vector-container-social span, .fa-calendar-days{
  color: #dfb3df;
}


.vector-container-social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #dfb3df;
}

.vector-container-social span {
  margin-top: 20px;
}

.social-box {
  width: 300px;
  height: 50px;
}

.face {
  width: 50px;
  height: 50px;
  
}


.social-box {
  display: flex;
 
}


.upcount-container {
  width: 70px;
  height: 70px;
  border-left: 1px solid #f3f3fa;
}

.followers-container {
  width: 150px;
  height: 70px;
  border-left: 1px solid #f3f3fa;
  border-left: 1px solid #f3f3fa;
}

.upcount-container,.followers-container,.pic-container {
  background-color: white;
}

.pic-container img {
  
  width: 100%;
  height: 100%;
}

.social-box {
  color: lightgray;
}




.upcount-container i{
  margin: 20px auto;
  font-size: 16px;
  color: black;
}


.followers-container {
  display: flex;
  flex-direction: column;
  
}

.amount {
  font-size: 20px;
  font-weight: bold;
  color: black;
}


.followers-container p {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.pic-container {
  
  width: 70px;
  height: 70px;

}


.social-media-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.social-media {
  padding: 30px;
  background-color: #f3f3fa;
  
  
}



footer {
  background-color:  #dfb3df ;
  height: 300px;
}



.footer-contact-container {
 
  margin: auto;
  border-bottom: 2px solid white;
  color: rgb(255, 255, 255);
  padding: 20px;
}


.footer-contact-container {
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.copy {
font-size: 14px;
  text-align: center;
  color: white;
}



  a {
    text-decoration: none;
  }

  

  .navbar a {
    margin: 10px;
  }






@media screen and (max-width: 768px) {
  .social-media {
    gap: 10px;
  }
  
}


@media screen and (max-width: 768px) {
  .social-media-container {
     gap: 80px;
  }
  
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  .social-media-container {
     gap: 200px;
  }
  
}


@media screen and (min-width: 1024px)  {
  .social-media-container {
     gap: 250px;
  }
  
}



@media screen and  (max-width: 1170px) {
  .landing-photo-interface {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

 
  
  .landing-description {
    width: 360px;
    margin-left: 50px;
  }


  .landing-description h1 {
    font-size: 50px;
    margin-right: 50px;
  }


  .landing-photo-interface img {
    width: 100px;
    height: 100%;
  }


  .interface-img-container {

    width: 350px;
  }

  .interface-img-container img {
    width: 100%;
    height: 100%;
  }
  

.img-landing-container img {
  width: 100%;
  height: 100%;
}

}

@media screen and  (max-width: 1009px) {
  .landing-photo-interface {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

 
  
  .landing-description {
    width: 350px;
    margin-left: 50px;
  }



.interface-img-container {
  width: 250px;
}

.interface-img-container img {
  width: 100%;
  
}
  
}





@media screen and (min-width: 280px) and (max-width: 768px) {
  


.img-landing-container {
  display: none;
}


.interface-img-container {
  position: relative;
}

.landing-description {
  position: absolute;
  margin-right: 140px;
  
}

.interface-img-container {
  margin-left: 150px;
}

.landing-description h1 {
  font-size: 25px;
  
}


.landing-description p {
  font-size: 13px;
  margin-left: 60px;
}

.landing-description a {
  width: 130px;
  margin-left: 70px;
}


}


@media  (min-width: 900px) and (max-width: 1009px) {
  .interface-img-container {
      width: 300px;
      height: 320px;
  }

  .img-landing-container {
    width: 200px;
  }

  .landing-description {
  margin-left: 50px;
  
  }


}

@media screen and  (min-width: 768px) and (max-width: 910px) {
  
  .landing-description h1{
    font-size: 30px;
    margin-left: 50px;
    
  }

 .landing-description a {
  margin-bottom: 10px;
 }

  .landing-description p {
    font-size: 16px;
    margin-left: 50px;
    
  }

  .landing-description a {
    width: 150px;
    margin-left: 80px;
  }

  .landing-description {
    width: 300px;
  
  }

  .img-landing-container {
    width: 200px;
  }

  .interface-img-container {
    margin-top: 5px;
  }

  
}


@media screen and  (  min-width: 768px) and (max-width: 850px) {
  
 .landing-description h1  {
  font-size: 25px;
  width: 150px;
  margin-right: 80px;

 }

 .landing-description {
  width: 200px;
 }

 .landing-description p {
  font-size: 15px;
  width: 150px;
  margin-left: 100px;
 }
 .img-landing-container {
  width: 200px;

}

}




@media screen and (min-width: 1024px) {
  .boxes-container {
    width: 70%;
  }
  
}


@media screen and (max-width: 600px) {
  .absolute-buttons-container button{
    width: 27%;
    font-size: 16px;
    
  }
  
}





@media screen and (max-width: 1024px) {
 

  .about-location-container {
    gap: 20px;
    
  }

  .about-description {
    align-items: center;
  }


  .about-description .vector-container {
    margin-right: 130px;
  }

  .checked-abilities-card {
  justify-content: center;
  }


}

@media  screen and (min-width:768px) and (max-width: 1600px) {
  .photos-container {
   
    width: 70%;
  }



  
}


